import React from 'react'
import ReactDOM from 'react-dom'
import Routes from './Router'
import store from './Store'
import * as serviceWorker from './serviceWorker'
import 'bootstrap/dist/css/bootstrap.min.css'
import './style.css'

export const ENVIRONMENT = 'development'
ReactDOM.render(
	<Routes store={store} />,
	document.getElementById('root')
);

serviceWorker.register();
