export function apiIsLoading(actionType, bool) {
    return {
        type: actionType,
        isLoading: bool
    };
}

export function apiDataSuccess(actionType, data, requestUrl) {
    return {
        type: actionType,
        payload:data,
        requestUrl
    };
}

export function apiHasErrored(errorMessage) {
    return {
        type: 'ERROR',
        payload: errorMessage
    };
}

export function apiFetch(url, request, loading, success, errorMessage, callback) {
    return (dispatch) => {
        dispatch(apiIsLoading(loading, true));
        fetch(url, request)
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                dispatch(apiIsLoading(loading, false));
                return response;
            })
            .then((response) => response.json())
            .then((data) => {
                dispatch(apiDataSuccess(success, data, url))
                typeof callback === 'function' && callback(true, { data }, dispatch)
            })
            .catch((err) => {
                dispatch(apiHasErrored(errorMessage))
                dispatch(apiIsLoading(loading, false))
                typeof callback === 'function' && callback(false, { err }, dispatch)
            });
    };
}