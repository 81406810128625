import { apiFetch } from '../App/api'

export function messages(actionObj) {
    let loading = 'MESSAGES_LOADING' 
    let success = 'MESSAGES_SUCCESS' 
    let errorMessage = 'Unable to load messages'

    var url = 'http://calvarychapelyelm.org/api/kioskmessages'

    let request = {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
    }
    
    return apiFetch(url, request, loading, success, errorMessage)
}