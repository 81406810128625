import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

//pages
import Home from './Pages/Home/Home'

const Routes= ({ store }) =>
    <Provider store={store}>
    	<Router>
    		<div className="container-fluid">
            	<Switch>
              		<Route exact path="/" component={Home} />
            	</Switch>
            </div>
     	</Router>
    </Provider>
export default Routes
