import React, { Component } from 'react'
import { connect } from "react-redux"
import { bindActionCreators } from 'redux'
import { setValue, events, messages } from '../../Actions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/free-regular-svg-icons'


class Message extends Component {
  getClass(val){
    if(val === 0)
      return 'carousel-item active'
    return 'carousel-item' 
  }

  render(){
    return(
      <div className={this.getClass(this.props.val)}>
        {this.props.obj.line1}
        <br/>
        {this.props.obj.line2}
      </div>
    )}
}

class Home extends Component {
  constructor(props) {
	super(props)
  this.myTimer = this.myTimer.bind(this)
  }
  
  componentDidMount(){
    this.myTimer()
    setInterval(this.myTimer, 100000);
  }

  myTimer(){
    this.props.eventAction()
    this.props.messagesAction()
  }

  render() {
	return (
    <div className="d-flex flex-column flex-grow-1 vh-100 overflow-hidden no-gutters">
        <div className="row flex-grow-1 overflow-hidden justify-content-center">
          <div className="d-flex align-items-center banner">
             
            Welcome to<br/>
            Calvary Chapel Yelm

          </div>
        </div>
        <div className="d-flex flex-column">
          <table className="table">
            <thead>
              <tr className="heading">
                <td colSpan="4"><div className="text-center title">Upcoming Events</div></td>
              </tr>
            </thead>
            <tbody>

            {this.props.events.map((object, i) => (
            <tr obj={ object } key={ i }>
              <td>
                <div className="row">
                    <div className="col-8">
                      <b>{object.title}</b>
                    </div>
                    <div className="col-4 text-right">
                      {object.dateShort}
                    </div>
                  </div>
                <div className="row">
                    <div className="col-12 tiny">Location: {object.location}</div>
                </div>
              </td>
            </tr>
            ))}

            </tbody>
          </table>
        </div>
        <div className="d-flex flex-column">
          <br/><br/>
        </div>
    </div>
	);
  }
}

const mapDispatchToProps = (dispatch) => ({
	setValue: bindActionCreators(setValue, dispatch),
  eventAction: bindActionCreators(events, dispatch),
  messagesAction: bindActionCreators(messages, dispatch),
});

const mapStateToProps = (state) => ({
  events: state.App.events,
  messages: state.App.messages,
});

export default connect(mapStateToProps, mapDispatchToProps)(Home)