const INITIAL_STATE = {
	events: [],
	messages: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
	case "EVENTS_SUCCESS":
	return {
		...state,
		events: action.payload,
	};
	case "MESSAGES_SUCCESS":
	return {
		...state,
		messages: action.payload,
	};
	default:
	  return state;
  }
};